::-webkit-scrollbar {
  width: 0px;
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: transparent;
}

::-webkit-scrollbar-thumb:hover {
  background-color: transparent;
}

html:has(#landing-page) {
  overflow-y: scroll !important;
  height: auto !important;
}

main,
section {
  position: relative;
}

#landing-page h1,
#landing-page h2,
#landing-page h3 {
  font-family: "simplify", sans-serif;
  font-weight: 200;
}

/* Header */
header {
  height: 100vh;
  position: relative;
}

.header-content {
  margin-top: 10%;
  margin-left: 15%;
}

.header-content h1 {
  color: var(--color-3);
  font-size: 3.5rem;
  font-weight: 200;
}

#lottie-write {
  width: 10rem;
  position: absolute;
  top: -4rem;
  left: -2rem;
}

.lottie-write-container {
  display: inline-block;
  position: relative;
}

.header-content hr {
  color: var(--color-2);
  width: 8%;
  border-width: 4px;
  opacity: 1;
}

.header-content p {
  font-size: 1.125rem;
  color: var(--color-2);
  text-transform: uppercase;
  line-height: 28px;
  letter-spacing: 2px;
}

.header-video {
  height: 100vh;
  width: 100%;
  position: relative;
}

.header-video .texture {
  position: absolute;
  width: 100%;
  bottom: 0;
}

.header-video video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.polygon-1 {
  background-color: var(--color-2);
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: -12.5%;
  clip-path: polygon(12.5% 0%, 25% 0%, 12.5% 100%, 0% 100%);
}

.polygon-2 {
  background-color: var(--color-3);
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: -15.625%;
  clip-path: polygon(6.25% 50%, 12.5% 50%, 6.25% 100%, 0% 100%);
}

.polygon-3 {
  background-color: var(--color-2);
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  clip-path: polygon(100% 0, 100% 0, 100% 100%, 90% 100%);
  z-index: 2;
}

/* -------- */
/* Welcome */

section.welcome .img-container {
  position: relative;
  display: contents;
}

section.welcome img {
  width: 50%;
  position: absolute;
  z-index: 101;
  bottom: 0;
}

.polygon-4 {
  width: 50%;
  height: 100%;
  background-color: var(--color-2);
}

.welcome-content {
  padding: 15% 0;
}

.welcome-content .paragraph-container {
  display: flex;
  justify-content: center;
  margin-top: 5%;
}

.welcome-content h2 {
  color: var(--color-3);
  font-size: 2.25rem;
  font-weight: 200;
}

.welcome-content p {
  text-transform: uppercase;
  color: var(--color-3);
  line-height: 28px;
  letter-spacing: 2px;
  font-size: 1rem;
}

.polygon-5 {
  background-color: var(--color-2);
  height: 90px;
  width: 90px;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 0);
  margin-right: 40px;
}

/* -------- */
/* How works */
section.how-works {
  padding: 0% 5% 10% 10%;
}

section.how-works h2 {
  color: var(--color-1);
  font-size: 2.25rem;
  text-align: center;
  padding-top: 5rem;
  margin-bottom: 10rem;
  font-weight: 200;
}

section.how-works h3 {
  color: var(--color-1);
  font-size: 1.25rem;
  text-align: center;
}

.polygon-6 {
  position: absolute;
  width: 15%;
  height: 70%;
  top: -1rem;
  left: -1rem;
  background-color: var(--color-3);
}

.written-repeat {
  height: 1.5rem;
  position: absolute;
  right: 25%;
  bottom: 0;
  transform: translateY(calc(50% - 1px));
  background-color: var(--color-2);
}

.written-repeat img {
  width: 10px;
  height: 10px;
}

.written-repeat span {
  font-family: "simplify", sans-serif;
  font-size: 1rem;
  margin: 0 1rem;
}

.how-works-steps-container-mobile {
  width: 100%;
  padding: 3rem 0;

  .lottie-container {
    margin: 0 auto 1rem;
  }

  .slider {
    display: flex;
    transition: transform 0.5s ease;
  }

  & .step {
    flex: 0 0 100%; /* Larghezza di ciascun item */

    & .line {
      width: 100%;
      margin-left: 0%;
      background-color: var(--color-2);
      display: flex;
      vertical-align: middle;
      position: relative;

      & hr {
        margin-left: 0%;
        width: 100%;
        border: none;
        border-top: 1px dashed black;
        opacity: 1;
      }

      & .point {
        position: absolute;
        width: 1.5rem;
        height: 1.5rem;
        border-radius: 50%;
        background-color: var(--color-1);
        border: 5px solid var(--color-3);
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 2;
      }
    }

    & .content {
      padding: 2rem 5rem;

      & p {
        text-transform: uppercase;
        letter-spacing: 2px;
        color: #677684;
        font-size: 0.75rem;
        text-align: center;
        line-height: 28px;
      }
    }
  }

  & .step:first-child {
    & .line {
      width: 55%;
      margin-left: 45%;

      & hr {
        margin-left: 10%;
      }

      & .point {
        left: 10%;
      }
    }
  }

  & .step:last-child {
    & .line {
      width: 60%;
      margin-right: 40%;
      margin-top: 5rem;

      & .written-repeat {
        align-items: center;
        display: flex;
        right: 15%;
        position: relative;
        height: auto;
        bottom: auto;
        transform: none;

        & img:last-child {
          transform: rotate(180deg);
        }
      }
    }
  }
}

.how-works-steps-container-web {
  display: flex;
  align-items: start;
  justify-content: center;
  position: relative;
  width: 100%;
  border-radius: 80px;
  box-shadow: 0px 0px 0px 15px rgba(225, 229, 6, 1),
    inset 0px 0px 0px 15px rgba(225, 229, 6, 1);
  -webkit-box-shadow: 0px 0px 0px 15px rgba(225, 229, 6, 1),
    inset 0px 0px 0px 15px rgba(225, 229, 6, 1);
  -moz-box-shadow: 0px 0px 0px 15px rgba(225, 229, 6, 1),
    inset 0px 0px 0px 15px rgba(225, 229, 6, 1);
  border: dashed 1px var(--color-1);

  & .lottie-container {
    position: absolute;
    top: -5rem;
    transform: translateX(-30%);
  }

  & .steps {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 3% 5%;
    z-index: 2;
  }

  & .step-item {
    flex: 1;
    padding: 0.5rem;
  }

  & .step-item h3 {
    margin-bottom: 1rem;
  }

  & .step-item p {
    text-transform: uppercase;
    letter-spacing: 2px;
    color: #677684;
    font-size: 0.75rem;
    text-align: center;
    line-height: 28px;
  }
}

.how-works-steps-container-web ol,
.how-works-steps-container-web li {
  margin: 0;
  padding: 0;
}

.how-works-steps-container-web ol {
  display: flex;
  width: 65%;
  justify-content: space-between;
  position: absolute;
  transform: translateY(-50%);

  &:after {
    content: "";
    display: block;
    width: 100%;
    height: 3px;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(0, -50%);
    z-index: 0;
  }
}

.how-works-steps-container-web li {
  list-style-type: none;
  position: relative;
  z-index: 1;

  &:after {
    content: "";
    display: block;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    background-color: var(--color-1);
    border: 5px solid var(--color-3);
  }
}

.slider-container {
  margin: 0;
  overflow: hidden;
}

.lottie-container {
  width: 4rem;
  height: 4rem;
}

/* ------------ */
/* How are you */
section.who-are-you {
  height: 100vh;
  background-image: url(../../images/landingPage/background-1.jpg);
  background-color: rgba(
    0,
    0,
    0,
    0.5
  ); /* Imposta il colore di sfondo del div a nero con un'opacità del 50% */
  background-blend-mode: multiply; /* Moltiplica il colore di sfondo con l'immagine di sfondo */
  background-size: cover;
  background-position: bottom;
  position: relative;
}

section.who-are-you h2 {
  color: var(--color-3);
  font-size: 2.25rem;
  text-align: center;
  margin-top: 5rem;
  font-weight: 200;
}

.dropdown-allabox-container {
  width: 100%;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.dropdown-allabox {
  border-radius: 50px;
  width: 35%;
  background-color: transparent;
  border: 2px solid var(--color-3);
  padding: 5px;
  cursor: pointer;
  position: relative;
}

.dropdown-allabox-toggle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 100%;
  background-color: var(--color-2);
  border-radius: 50px;
  padding: 5px 1rem;
  border: none;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.dropdown-allabox-menu {
  position: absolute;
  z-index: 2;
  top: 100%;
  left: 0;
  width: 100%;
  border-radius: 1rem;
  padding: 1rem 0;
  margin-top: 0.5rem;
  background-color: var(--color-3);
}

.dropdown-allabox-item {
  display: flex;
  align-items: center;
  column-gap: 1rem;
  padding: 1rem;
  white-space: normal;

  & img {
    width: 3rem;
    height: auto;
  }

  & label {
    font-size: 1rem;
    font-weight: bold;
    letter-spacing: 1px;
    text-transform: uppercase;
    display: block;
    cursor: pointer;
  }

  & span {
    font-size: 0.875rem;
    color: #677684;
    text-transform: initial;
  }

  &:not(:last-child) {
    border-bottom: 1px dotted #677684;
  }
}

.dropdown-allabox-item:active {
  color: var(--color-1);
}

.dropdown-allabox-item:hover {
  background-color: #f0f0f0;
}

/* Allabox Login */
section.allabox-login {
  padding: 4rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

section.allabox-login h2 {
  color: var(--color-3);
  font-size: 2.25rem;
  text-align: center;
  font-weight: 200;
}

section.allabox-login p {
  font-size: 1.125rem;
  color: var(--color-3);
  text-transform: uppercase;
  line-height: 28px;
  letter-spacing: 2px;
  text-align: center;
  margin-top: 2rem;
}

section.allabox-login a {
  margin-top: 2rem;
  background-color: var(--color-2);
  color: var(--color-1);
  font-size: 1rem;
  padding: 0.5rem 2rem;
  text-align: center;
  border: 5px solid var(--color-2);

  &:hover {
    background-color: var(--color-3);
  }
}

/* Contact us */
section.contact-us h2 {
  font-size: 2.25rem;
  font-weight: 200;
}

.final-img {
  height: 100%;
  width: 100%;
  position: relative;
  background-image: url(../../images/landingPage/final-img.jpg);
  background-size: cover;
  background-position: bottom;
  position: relative;
}

.form-container {
  padding: 5rem 0 5rem 15%;

  & p {
    font-size: 1rem;
    color: var(--color-1);
    text-transform: uppercase;
    line-height: 28px;
    letter-spacing: 1px;
    margin-top: 1rem;
  }
  & form {
    display: flex;
    flex-direction: column;

    & label {
      color: var(--color-1);
      font-size: 0.75rem;
      margin-top: 1rem;
    }
  }

  & input:not([type="checkbox"]),
  select,
  button {
    width: 75%;
  }
}

@media screen and (max-width: 992px) {
  .web {
    display: none;
  }

  header {
    height: auto;
  }

  .navbar-allabox {
    padding: 2rem;
  }

  .header-content {
    padding: 2rem;
    margin: 0;
  }

  .header-video .polygon-1 {
    display: none;
  }

  .header-video .polygon-3 {
    display: none;
  }

  .header-video {
    height: 30vh;
  }

  .header-video .texture {
    height: 100%;
  }

  section.welcome .img-container {
    display: flex;
    justify-content: center;
    background-color: var(--color-2);
    background-image: url(../../images/landingPage/texture-dark.png);
    background-repeat: repeat;
    width: 100%;
  }

  section.welcome .polygon-4 {
    display: none;
  }

  section.welcome img {
    position: relative;
    width: 100%;
  }

  section.welcome .welcome-content {
    padding: 2rem;
  }

  .polygon-5 {
    display: none;
  }

  section.welcome .paragraph-container {
    display: block;
  }

  section.how-works {
    padding: 0;
  }

  section.contact-us {
    & .form-container {
      padding: 5rem 15%;

      & input,
      select,
      button {
        width: 100%;
      }

      & h2,
      p {
        text-align: center;
      }
    }
  }

  & .final-img {
    height: 20rem;
    background-position: center;
  }

  section.who-are-you .dropdown-allabox {
    width: 70%;
  }
}

@media screen and (min-width: 992px) {
  .mobile {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .header-content h1,
  .welcome h2 {
    font-size: 2rem;
    font-weight: 200;
  }

  #lottie-write {
    width: 6rem;
    position: absolute;
    top: -2.5rem;
    left: -1rem;
  }

  .header-content p,
  .paragraph-container p {
    font-size: 0.75rem;
  }

  section.allabox-login-allabox a {
    width: 100%;
  }

  section.contact-us .form-container {
    padding: 2rem;
  }

  section.who-are-you .dropdown-allabox {
    width: 100%;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .h1 {
    font-size: 2.75rem;
    text-align: center;
  }

  .header-content p {
    font-size: 1rem;
    text-align: center;
  }

  .paragraph-container p {
    font-size: 1rem;
  }

  .line-1 {
    margin: 1rem auto;
  }

  section.who-are-you .who-are-you-select {
    width: 80%;
  }
}

footer {
  position: relative;
  display: flex;
  background-color: var(--color-1);
  color: var(--color-1);
  width: 100%;
  padding: 1rem;
  justify-content: space-between;
  column-gap: 1rem;
}

footer img {
  width: 6rem;
  margin-right: 3rem;
}

footer label {
  color: var(--color-3);
  font-size: 0.625rem;
  letter-spacing: 1px;
}

footer a {
  color: var(--color-2);
  font-size: 0.625rem;
  text-transform: uppercase;
}

footer a:hover {
  color: var(--color-2);
}

.navbar-allabox {
  background-color: transparent;
  padding: 1rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.logo-allabox {
  display: flex;
  align-items: center;
}

.login-allabox a {
  color: var(--color-2);
  letter-spacing: 2px;
}

#animated-logo {
  width: 3.875rem;
  height: auto;
}

.wrapper {
  display: flex;
  flex-wrap: wrap;
}

.col-40 {
  flex: 0 0 40%;
  max-width: 40%;
}

.col-60 {
  flex: 0 0 60%;
  max-width: 60%;
}

.scroller-allabox {
  position: absolute;
  left: 4%;
  border-left: 2px dashed;
  height: 100%;
  z-index: 100;
}

.scroller-allabox.dark {
  border-color: var(--color-1);
}

.scroller-allabox.light {
  border-color: var(--color-2);
}

.scroller-allabox-icon {
  position: fixed; /* Posizionamento assoluto rispetto all'elemento genitore (.scroller-allabox) */
  top: 12rem;
  left: calc(4% - 13px); /* 13px = width icon / 2 */
  z-index: 102;
}

header .scroller-allabox {
  top: 12rem; /* (250px) */
  height: calc(100vh - 12rem);
}

section.contact-us .scroller-allabox {
  height: 16rem;
}

@media screen and (max-width: 991px) {
  .scroller-allabox {
    display: none;
  }

  .scroller-allabox-icon {
    display: none;
  }
}

.my-slider {
  margin-top: 2.75rem;
}

.my-slides {
  display: flex;
  overflow-x: auto;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
}

.my-slides::-webkit-scrollbar {
  display: none !important;
}
.my-slides::-webkit-scrollbar-thumb {
  background: transparent !important;
  border-radius: 10px;
}
.my-slides::-webkit-scrollbar-track {
  background: transparent !important;
}

/* Slide */
.my-slide-wrap {
  margin-right: 1.5rem;
}

.my-slide {
  flex: 0 0 20rem;
  height: 20rem;
  width: 20rem;
  margin: 0 10px;
  background-color: var(--color-3);
  border-top: 5px solid var(--color-2);
  border-right: 5px solid var(--color-2);
  border-radius: 3px;
  padding: 1rem;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  & .icon-container,
  .description-container {
    flex: 1;
    padding: 1rem;
    text-align: center;
  }

  & .description-container label {
    font-size: 1.125rem;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: var(--color-1);
  }

  & .description-container p {
    font-size: 15px;
    line-height: 22px;
    margin-top: 0.5rem;
    margin-bottom: 0;
    color: #677684;
  }
}

.polygon-7 {
  background-color: var(--color-2);
  height: 46px;
  width: 42px;
  position: absolute;
  top: 0;
  left: 0;
  clip-path: polygon(0 0, 100% 0, 0% 100%);
  margin: 1rem;
}

.my-slider-controller {
  text-align: center;
  margin-top: 1rem;
  display: flex;
  justify-content: center;

  & div {
    width: 3rem; /* Larghezza del div rotondo */
    height: 3rem; /* Altezza del div rotondo */
    border-radius: 50%; /* Rende il div rotondo */
    background-color: var(--color-2); /* Colore di sfondo del div */
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 10px;
    cursor: pointer;

    &.prev img {
      transform: rotate(0deg);
    }

    &.next img {
      transform: rotate(-180deg);
    }

    & img {
      width: 1rem;
      height: 1rem;
    }
  }

  & div:hover {
    background-color: var(--color-3);
    border: 3px solid var(--color-2);
  }
}

/* Responsive */
@media (max-width: 575.98px) {
}

/* xs sm md */
@media (max-width: 991.98px) {
  .my-slide-wrap {
    margin: 0;
  }

  .my-slide {
    height: 100%;
  }
}

.texture-light {
  background-image: url(../../images/landingPage/texture-light.png);
  background-repeat: repeat;
}

.texture-light-2 {
  background-image: url(../../images/landingPage/texture-light-2.png);
  background-repeat: repeat;
}

.texture-dark {
  background-image: url(../../images/landingPage/texture-dark.png);
  background-repeat: repeat;
}

.texture-dark-2 {
  background-image: url(../../images/landingPage/texture-dark-2.png);
  background-repeat: repeat;
}
