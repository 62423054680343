.list-container {
  .no-data {
    padding: 2rem;
    background-color: var(--color-3);
    text-align: center;
    font-size: 1rem;
  }

  .filters {
    position: relative;
    width: 100%;
    background-color: white;
    margin-bottom: 2rem;
    padding: 1rem;
    border-radius: 5px;
    box-shadow: 0 6px 10px rgba(50, 51, 94, 0.15); // Ombra standard
  }

  .data-report {
    font-size: 0.75rem;
    font-weight: 300;
    color: var(--color-5);
    font-style: italic;
  }

  // .actions-bar {
  //   height: 5rem;
  // }

  .actions-bar.sticky {
    position: sticky;
    top: 0;
    z-index: 1;
    padding-bottom: 1rem;
  }

  .actions-bar.fixed {
    background-color: var(--color-3);
    box-shadow: 0 6px 10px rgba(50, 51, 94, 0.15);
  }

  .list {
    .list-item-container {
      display: flex;
      gap: 10px;

      & .select-row-column {
        flex-shrink: 0;
        display: flex;
        align-items: center;
      }
      & .item-column {
        flex-grow: 1;
      }
    }
  }

  .list-item {
    width: 100%;
    background-color: var(--color-3);
    border-radius: 6px;
    margin: 0.5rem 0;
    padding: 0.5rem 0;

    .bg-grey {
      background-color: var(--color-4);
      height: 100%;
      padding: 0.625rem 0.75rem;
    }

    img {
      max-width: 3rem;
      max-height: 2rem;
    }

    img.icon {
      width: 1rem;
    }

    .status {
      border-radius: 20px;
      text-align: center;
      font-weight: 500;
      color: var(--color-3);
      padding: 0.25rem;
      font-size: 0.75rem;
    }

    hr {
      border: none;
      border-top: 2px dotted var(--color-5); // Puoi cambiare il colore a tuo piacimento
    }
  }

  .pagination {
    display: flex;
    align-items: center;
    column-gap: 0.625rem;

    & .number {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 2rem;
      height: 2rem;
      font-size: 0.75rem;
      font-weight: bold;
      cursor: pointer;
      border-radius: 3px;

      &.active {
        background-color: var(--color-1);
        color: var(--color-3);
      }

      &:hover {
        color: var(--color-1);
        background-color: var(--color-2);
      }
    }
  }
}

.list-item.compact-sm {
  // background-color: red;

  .title {
    font-size: 10px;
  }
}
