.span-1 {
  font-size: 0.75rem;
  color: var(--color-5);
}

.status {
  border-radius: 20px;
  text-align: center;
  font-weight: 500;
  color: var(--color-3);
  padding: 0.25rem;
  font-size: 0.75rem;
}
