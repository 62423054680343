@import "./bootstrap.scss";
@import "./bootstrap_limitless.scss";
@import "./components.scss";
@import "./layout.scss";
@import "../../../../icons/phosphor/styles.min.css";

@media (min-width: 768px) {
  .custom-scrollbars * {
    -ms-overflow-style: -ms-autohiding-scrollbar;
    scrollbar-width: thin;
    scrollbar-color: var(--gray-600) var(--gray-100);
  }
  .custom-scrollbars ::-webkit-scrollbar {
    height: 0.5rem;
    width: 0.5rem;
  }
  .custom-scrollbars ::-webkit-scrollbar-track {
    background: var(--gray-100);
  }
  .custom-scrollbars ::-webkit-scrollbar-thumb {
    background-color: var(--gray-600);
    border-radius: 0.5rem;
  }
}

.page-content {
  height: calc(100vh - 63px);
}

.search-dropdown {
  .dropdown-toggle {
    bottom: 4px;
  }
  .dropdown-toggle::after {
    display: none;
  }
  .dropdown-menu {
    transform: translate(0) !important;
  }
}

/* Yellow Range Slider */
#range-slider-yellow {
  background: url("data:image/svg+xml,%3Csvg width='30' height='30' viewBox='0 0 40 40' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23555' fill-rule='evenodd'%3E%3Cpath d='M0 40L40 0H20L0 20M40 40V20L20 40'/%3E%3C/g%3E%3C/svg%3E")
    #333;
}

#range-slider-yellow .range-slider__range {
  background: #ffbf00;
  transition: height 0.3s;
}

#range-slider-yellow .range-slider__thumb {
  background: #faa307;
  transition: transform 0.3s;
}

#range-slider-yellow .range-slider__thumb[data-active] {
  transform: translate(-50%, -50%) scale(1.25);
}

#range-slider-yellow .range-slider__range[data-active] {
  height: 16px;
}

/* AB Range Slider */
#range-slider-ab {
  height: 16px;
  background: #4b4d61;
}

#range-slider-ab .range-slider__range {
  background: #ff4141;
}

#range-slider-ab .range-slider__thumb {
  width: 30px;
  height: 30px;
  border-radius: 4px;
}

#range-slider-ab .range-slider__thumb[data-lower] {
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='' width='30' height='30' viewBox='0 0 24 24'%3E%3Cpath d='M3,5A2,2 0 0,1 5,3H19A2,2 0 0,1 21,5V19A2,2 0 0,1 19,21H5C3.89,21 3,20.1 3,19V5M11,7A2,2 0 0,0 9,9V17H11V13H13V17H15V9A2,2 0 0,0 13,7H11M11,9H13V11H11V9Z' /%3E%3C/svg%3E")
    #ff4141;
}

#range-slider-ab .range-slider__thumb[data-upper] {
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 24 24'%3E%3Cpath d='M5,3H19A2,2 0 0,1 21,5V19A2,2 0 0,1 19,21H5A2,2 0 0,1 3,19V5A2,2 0 0,1 5,3M15,10.5V9A2,2 0 0,0 13,7H9V17H13A2,2 0 0,0 15,15V13.5C15,12.7 14.3,12 13.5,12C14.3,12 15,11.3 15,10.5M13,15H11V13H13V15M13,11H11V9H13V11Z' /%3E%3C/svg%3E")
    #ff4141;
}

#range-slider-ab .range-slider__thumb[data-lower][data-active] {
  animation: rotate-anti-clockwise 0.9s infinite;
}

#range-slider-ab .range-slider__thumb[data-upper][data-active] {
  animation: rotate-clockwise 0.9s infinite;
}

@keyframes rotate-clockwise {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }

  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

@keyframes rotate-anti-clockwise {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }

  100% {
    transform: translate(-50%, -50%) rotate(-360deg);
  }
}

/* Gradient Range Slider */
#range-slider-gradient {
  height: 24px;
  border-radius: 12px;
  background: #353644;
}

#range-slider-gradient .range-slider__thumb {
  border: 6px solid #fff;
  box-sizing: border-box;
}

#range-slider-gradient .range-slider__thumb[data-lower] {
  background: #0073e6;
}

#range-slider-gradient .range-slider__thumb[data-upper] {
  background: #ee2c2c;
}

#range-slider-gradient .range-slider__range {
  background: linear-gradient(to right, #0073e6, #ee2c2c);
  background-size: 200% 100%;
  background-position: 50% 0;
}

#range-slider-gradient .range-slider__range[data-active],
#range-slider-gradient
  .range-slider__thumb[data-active]
  ~ .range-slider__range {
  animation: move-bg 0.75s infinite linear;
}

@keyframes move-bg {
  0% {
    background-position: 50% 0;
  }

  25% {
    background-position: 100% 0;
  }

  50% {
    background-position: 50% 0;
  }

  75% {
    background-position: 0% 0;
  }

  100% {
    background-position: 50% 0;
  }
}

/* Range Slider as a Single Thumb Slider */
.single-thumb .range-slider__thumb[data-lower] {
  width: 0;
}
.single-thumb .range-slider__range {
  border-radius: 6px;
}

/* Vertical Range Slider */
.vertical-slider[data-vertical] {
  height: 180px;
}

// nav-group-sub

.nav-group-sub {
  .nav-item {
    [data-bs-toggle="collapse"] {
      // Direction arrow
      &:after {
        content: $icon-caret-right;
        font-family: var(--#{$prefix}icon-font-family);
        display: inline-block;
        font-size: var(--#{$prefix}body-font-size);
        vertical-align: middle;
        line-height: 1;
        position: absolute;
        top: var(--#{$prefix}nav-link-padding-y);
        margin-top: calc(
          calc(
              var(--#{$prefix}body-line-height-computed) -
                var(--#{$prefix}body-font-size)
            ) * 0.5
        );
        right: var(--#{$prefix}nav-link-padding-x);
        @include transition(
          transform ease-in-out var(--#{$prefix}transition-collapse-timer)
        );
        @include ll-font-smoothing();

        // RTL
        [dir="rtl"] & {
          content: $icon-caret-left;
        }
      }
    }
  }
}

// progress nav
.progress-nav {
  position: relative;
  margin-right: 1rem;
  margin-left: 1rem;
  margin-top: 1rem;
  padding: 0 1rem;

  .progress {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
  }

  .nav {
    margin-right: -1rem;
    margin-left: -1rem;
    justify-content: space-between;
    position: relative;
    z-index: 9;

    .nav-link {
      width: 2rem;
      height: 2rem;
      background-color: var(--#{$prefix}light);
      padding: 0;
      color: var(--#{$prefix}dark);
      font-weight: $font-weight-medium;
      display: flex;
      justify-content: center;

      &.active,
      &.done {
        background-color: $primary;
        color: $white;
      }
    }
  }
}

// .rct-node-icon{
//   color: var(--body-color);
// }

@each $color, $value in $theme-colors {
  .noUi-#{$color} {
    .noUi-connect {
      background-color: #{$value};
    }
  }
}

.custom-progress {
  width: 20px !important;
  height: 20px !important;
}

//react select

[data-color-theme="dark"] {
  .css-b62m3t-container {
    .css-13cymwt-control {
      background-color: var(--#{$prefix}gray-100);
      color: var(--#{$prefix}body-color);
      border-color: var(--#{$prefix}border-color);
      &:hover,
      &:focus {
        border-color: var(--#{$prefix}primary);
      }
      .css-1p3m7a8-multiValue {
        color: var(--#{$prefix}white);
        background-color: var(--#{$prefix}primary);
        .css-wsp0cs-MultiValueGeneric {
          color: var(--#{$prefix}white);
        }
        .css-1u9des2-indicatorSeparator {
          border-color: var(--#{$prefix}border-color);
        }
      }
    }
    :is(.css-qbdosj-Input, .css-1dimb5e-singleValue, .css-166bipr-Input) {
      color: var(--#{$prefix}body-color);
    }
    .css-t3ipsp-control {
      background-color: var(--#{$prefix}gray-100);
      color: var(--#{$prefix}body-color);
      border-color: var(--#{$prefix}border-color);
      .css-1p3m7a8-multiValue {
        color: var(--#{$prefix}white);
        background-color: var(--#{$prefix}primary);
        .css-wsp0cs-MultiValueGeneric {
          color: var(--#{$prefix}white);
        }
        .css-1u9des2-indicatorSeparator {
          border-color: var(--#{$prefix}border-color);
        }
      }
    }
    .css-1nmdiq5-menu {
      background-color: var(--#{$prefix}gray-100);
      color: var(--#{$prefix}body-color);
      border-color: var(--#{$prefix}border-color);
      .css-d7l1ni-option {
        color: $white;
        background-color: var(--#{$prefix}primary);
        &:hover,
        &:active,
        &:focus {
          background-color: var(--#{$prefix}primary);
        }
      }
    }
  }

  .rdl-filter,
  .rdl-control {
    background-color: var(--#{$prefix}gray-100);
    border: 1px solid $input-border-color !important;
    color: $input-color !important;
  }
  .rdl-move {
    background: var(--#{$prefix}light) !important;
    border: 1px solid var(--#{$prefix}light) !important;
    color: var(--#{$prefix}body-color) !important;
  }
  .react-dual-listbox {
    input:disabled,
    select:disabled {
      background-color: $input-disabled-bg !important;
      border: 1px solid $input-border-color !important;
    }
  }
  .css-26l3qy-menu {
    background-color: var(--#{$prefix}gray-200) !important;
    border-color: var(--#{$prefix}border-color) !important;
  }
  .css-1n7v3ny-option {
    background-color: var(--#{$prefix}gray-300) !important;
    border-color: var(--#{$prefix}border-color) !important;
  }

  #demo_config {
    &.offcanvas {
      border: none;
    }
  }
}

.rdl-control-container {
  .rdl-control {
    option {
      padding: 5px 0;
    }
  }
}
.react-table {
  min-height: 400px;
  .table-image {
    max-height: 30px;
    max-width: 100px;
  }
  table {
    tfoot {
      td {
        padding-top: 10px;
      }
    }
  }
}
