//
// Check/radio
//

.form-check {
  --#{$prefix}form-check-min-height: #{$form-check-min-height};
  --#{$prefix}form-check-padding-start: #{$form-check-padding-start};
  --#{$prefix}form-check-margin-bottom: #{$form-check-margin-bottom};

  display: block;
  min-height: var(--#{$prefix}form-check-min-height);
  padding-left: var(--#{$prefix}form-check-padding-start);
  margin-bottom: var(--#{$prefix}form-check-margin-bottom);

  .form-check-input {
    float: left;
    margin-left: calc(var(--#{$prefix}form-check-padding-start) * -1);
  }
}

.form-check-reverse {
  padding-right: var(--#{$prefix}form-check-padding-start);
  padding-left: 0;
  text-align: right;

  .form-check-input {
    float: right;
    margin-right: calc(var(--#{$prefix}form-check-padding-start) * -1);
    margin-left: 0;
  }
}

.form-check-input {
  --#{$prefix}form-check-input-width: #{$form-check-input-width};
  --#{$prefix}form-check-input-height: #{$form-check-input-height};
  --#{$prefix}form-check-input-bg: #{$form-check-input-bg};
  --#{$prefix}form-check-input-border: #{$form-check-input-border};
  --#{$prefix}form-check-input-border-radius: #{$form-check-input-border-radius};
  --#{$prefix}form-check-input-focus-border: #{$form-check-input-focus-border};
  --#{$prefix}form-check-input-focus-box-shadow: #{$form-check-input-focus-box-shadow};
  --#{$prefix}form-check-input-checked-bg-color: #{$form-check-input-checked-bg-color};
  --#{$prefix}form-check-input-checked-border-color: #{$form-check-input-checked-border-color};
  --#{$prefix}form-check-input-disabled-opacity: #{$form-check-input-disabled-opacity};
  --#{$prefix}form-check-input-indeterminate-bg-color: #{$form-check-input-indeterminate-bg-color};
  --#{$prefix}form-check-input-indeterminate-border-color: #{$form-check-input-indeterminate-border-color};
  --#{$prefix}form-check-checked-bg-color-rgb: #{$form-check-input-checked-bg-color-rgb};
  --#{$prefix}form-check-checked-border-color: #{$form-check-input-checked-border-color};
  --#{$prefix}form-check-radio-border-radius: #{$form-check-radio-border-radius};

  width: var(--#{$prefix}form-check-input-width);
  height: var(--#{$prefix}form-check-input-height);
  margin-top: calc(calc(var(--#{$prefix}body-line-height) - var(--#{$prefix}form-check-input-height)) * .5); // line-height minus check height
  vertical-align: top;
  background-color: var(--#{$prefix}form-check-input-bg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: var(--#{$prefix}form-check-input-border);
  appearance: none;
  print-color-adjust: exact; // Keep themed appearance for print
  @include transition($form-check-transition);

  &[type="checkbox"] {
    @include border-radius(var(--#{$prefix}form-check-input-border-radius));
  }

  &[type="radio"] {
    border-radius: var(--#{$prefix}form-check-radio-border-radius);
  }

  &:active {
    filter: $form-check-input-active-filter;
  }

  &:focus {
    border-color: var(--#{$prefix}form-check-input-focus-border);
    outline: 0;
    box-shadow: var(--#{$prefix}form-check-input-focus-box-shadow);
  }

  &:checked {
    background-color: var(--#{$prefix}form-check-input-checked-bg-color);
    border-color: var(--#{$prefix}form-check-input-checked-border-color);

    &[type="checkbox"] {
      @if $enable-gradients {
        background-image: escape-svg($form-check-input-checked-bg-image), var(--#{$prefix}gradient);
      } @else {
        background-image: escape-svg($form-check-input-checked-bg-image);
      }
    }

    &[type="radio"] {
      @if $enable-gradients {
        background-image: escape-svg($form-check-radio-checked-bg-image), var(--#{$prefix}gradient);
      } @else {
        background-image: escape-svg($form-check-radio-checked-bg-image);
      }
    }
  }

  &[type="checkbox"]:indeterminate {
    background-color: var(--#{$prefix}form-check-input-indeterminate-bg-color);
    border-color: var(--#{$prefix}form-check-input-indeterminate-border-color);

    @if $enable-gradients {
      background-image: escape-svg($form-check-input-indeterminate-bg-image), var(--#{$prefix}gradient);
    } @else {
      background-image: escape-svg($form-check-input-indeterminate-bg-image);
    }
  }

  &:disabled {
    pointer-events: none;
    filter: none;
    opacity: var(--#{$prefix}form-check-input-disabled-opacity);
  }

  // Use disabled attribute in addition of :disabled pseudo-class
  // See: https://github.com/twbs/bootstrap/issues/28247
  &[disabled],
  &:disabled {
    ~ .form-check-label {
      cursor: default;
      opacity: var(--#{$prefix}form-check-label-disabled-opacity);
    }
  }
}

.form-check-label {
  --#{$prefix}form-check-label-disabled-opacity: #{$form-check-label-disabled-opacity};
  color: $form-check-label-color;
  cursor: $form-check-label-cursor;
}

//
// Switch
//

.form-switch {
  --#{$prefix}form-check-padding-start: #{$form-switch-padding-start};

  .form-check-input {
    --#{$prefix}form-check-input-width: #{$form-switch-width};
    --#{$prefix}form-check-input-border-radius: #{$form-switch-border-radius};
    background-image: escape-svg($form-switch-bg-image);
    background-position: left center;
    @include transition($form-switch-transition);

    &:focus {
      background-image: escape-svg($form-switch-focus-bg-image);
    }

    &:checked {
      background-position: $form-switch-checked-bg-position;

      @if $enable-gradients {
        background-image: escape-svg($form-switch-checked-bg-image), var(--#{$prefix}gradient);
      } @else {
        background-image: escape-svg($form-switch-checked-bg-image);
      }
    }
  }
}

.form-check-inline {
  --#{$prefix}form-check-inline-margin-end: #{$form-check-inline-margin-end};
  display: inline-block;
  margin-right: var(--#{$prefix}form-check-inline-margin-end);
}

.btn-check {
  --#{$prefix}form-check-btn-check-disabled-opacity: #{$form-check-btn-check-disabled-opacity};
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;

  &[disabled],
  &:disabled {
    + .btn {
      pointer-events: none;
      filter: none;
      opacity: var(--#{$prefix}form-check-btn-check-disabled-opacity);
    }
  }
}
