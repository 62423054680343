.button {
  font-size: 1rem;
  padding: 0.5rem 1rem;
  text-align: center;
  z-index: 2;
  border: 5px solid;

  &.button-primary {
    background-color: var(--color-1);
    color: var(--color-3);
    border: 2px solid var(--color-3);

    &:hover {
      background-color: var(--color-3);
      color: var(--color-1);
      border: 2px solid var(--color-1);
    }

    &:hover:is([disabled]) {
      background-color: var(--color-1);
      color: var(--color-3);
      cursor: auto;
    }

    &:is([disabled]) {
      background-color: var(--color-1);
      opacity: 0.65;
    }
  }

  &.button-secondary {
    background-color: var(--color-2);
    color: var(--color-1);
    border-color: var(--color-2);

    &:hover {
      background-color: var(--color-3);
      color: var(--color-1);
    }

    &:hover:is([disabled]) {
      background-color: var(--color-2);
      cursor: auto;
    }

    &:is([disabled]) {
      background-color: var(--color-2);
      opacity: 0.5;
    }
  }

  &.button-dark {
    background-color: var(--color-1);
    border-radius: 10px;
    color: var(--color-2);
    border-color: var(--color-1);

    &:hover {
      background-color: var(--color-2);
      border-color: var(--color-2);
      color: var(--color-1);
    }
  }
}

.button-dynamic-1 {
  position: relative;
  width: fit-content;
  display: flex;
  padding: 0.5rem 1.5rem;
  align-items: center;
  border: none;
  border-top: 5px solid var(--color-2);
  background: linear-gradient(to bottom right, #eff0f6, #d9dfe6);
  column-gap: 1rem;
  color: var(--color-1);
  overflow: hidden;
  transition: color 0.3s ease;
  text-transform: uppercase;

  span,
  label {
    font-size: 0.875rem;
    font-weight: 600;
    z-index: 1;
    cursor: pointer;
  }

  svg {
    z-index: 1;
    font-size: 1.5rem;
  }

  &:hover {
    color: var(--color-5);
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 0;
    background: linear-gradient(to bottom right, var(--color-2), #e2fa4e);
    transition: height 0.3s ease;
    z-index: 0;
  }

  &:hover::before {
    height: 100%;
  }
}

.button-1 {
  display: inline-flex;
  column-gap: 0.5rem;
  justify-content: center;
  align-items: center;
  padding: 0.625rem 0.5rem;
  border-radius: 4px;
  text-decoration: none;
  cursor: pointer;
  transition: background-color 0.3s ease, border-color 0.3s ease;
  font-size: 0.875rem;

  &.button-1-icon {
    width: 2rem;
    height: 2rem;
    padding: 0;
  }

  &.button-1-light {
    border: 2px solid var(--color-4);
    background-color: var(--color-3);
    color: var(--color-1);

    &:hover {
      background-color: var(--color-4);
      border-color: var(--color-4);

      &:disabled {
        background-color: var(--color-3);
      }
    }

    svg {
      color: var(--color-1);
    }
  }

  &.button-1-dark {
    border: 2px solid var(--color-1);
    background-color: var(--color-1);
    color: var(--color-3);

    &:hover {
      opacity: 0.85;
    }

    svg {
      color: var(--color-3);
    }
  }

  &.button-1-transparent {
    border: 1px solid var(--color-5);
    background-color: transparent;
    color: var(--color-5);

    &:hover {
      opacity: 0.95;
    }

    svg {
      color: var(--color-5);
    }
  }

  &.button-1-danger {
    border: 2px solid var(--color-danger);
    background-color: var(--color-danger);
    color: var(--color-3);

    &:hover {
      opacity: 0.95;
    }

    svg {
      color: var(--color-3);
    }
  }

  &:disabled,
  &:disabled:hover {
    opacity: 0.3;
    cursor: default;
  }
}

.button-label-1 {
  font-size: 1rem;
  color: var(--color-1);
  background-color: transparent;
  border: none;
  font-weight: lighter;
}
