/* Colors */
:root {
  --color-1: #191919;
  --color-2: #e1e506;
  --color-3: #fff;
  --color-4: #f4f6f9;
}

.bg-color-1 {
  background-color: var(--color-1);
}
