/* ------------------------------------------------------------------------------
 *
 *  # Floating labels component overrides
 *
 * ---------------------------------------------------------------------------- */

.form-floating {
    --#{$prefix}form-floating-label-readonly-transform: #{$form-floating-label-readonly-transform};

    // With icons
    &.form-control-feedback-start > label {
        left: calc(var(--#{$prefix}form-floating-padding-x) + var(--#{$prefix}icon-font-size));
    }
    &.form-control-feedback-end > label {
        right: calc(var(--#{$prefix}form-floating-padding-x) + var(--#{$prefix}icon-font-size));
    }
}
