.homepage-header {
  background-image: url("../../images/landingPage/background-1.jpg");
  background-size: cover;
  padding: 3rem 2.5rem 1.5rem;
  height: auto;
  display: block;
  border-radius: 6px;
  position: relative;

  .title {
    position: relative;
    color: var(--color-3);
    margin-bottom: 0.5rem;
    z-index: 1;
  }

  .role {
    position: relative;
    color: var(--color-2);
    text-transform: uppercase;
    z-index: 1;
    letter-spacing: 1px;
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.9),
      rgba(0, 0, 0, 0.6)
    );
    border-radius: 6px;
  }

  .rapid-links {
    display: flex;
    position: absolute;
    right: 5%;
    bottom: -2rem;
    z-index: 2;
    column-gap: 1rem;
  }

  .link {
    position: relative;
    width: 220px;
    display: flex;
    padding: 0.5rem 1.5rem;
    align-items: center;
    border-top: 5px solid var(--color-2);
    background: linear-gradient(to bottom right, #eff0f6, #d9dfe6);
    column-gap: 1rem;
    color: var(--color-1);
    overflow: hidden;
    transition: color 0.3s ease;

    span {
      font-size: 0.875rem;
      font-weight: 600;
      z-index: 1;
    }

    svg {
      z-index: 1;
    }
  }

  .link::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 0;
    background: linear-gradient(to bottom right, var(--color-2), #e2fa4e);
    transition: height 0.3s ease;
    z-index: 0;
  }

  .link:hover::before {
    height: 100%;
  }
}

.report-container {
  display: flex;
}

.report {
  position: relative;
  border-radius: 10px;
  width: calc(25% - 0.75rem);
  margin-right: 1rem;
  padding: 1rem;

  .polygon {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  &:nth-child(1) {
    background: linear-gradient(to left bottom, #54f066, #e2fa4e);

    .polygon {
      clip-path: polygon(0 0, 40% 0, 20% 100%, 0% 100%);
      background: linear-gradient(to right bottom, #54f066, #e2fa4e);
    }
  }
  &:nth-child(2) {
    background: linear-gradient(to right, #54f066, #e2fa4e);

    .polygon {
      clip-path: polygon(0 0, 0% 100%, 50% 100%);
      background: linear-gradient(to bottom, #54f066, #e2fa4e);
    }
  }
  &:nth-child(3) {
    background: linear-gradient(to left, #54f066, #e2fa4e);

    .polygon {
      clip-path: polygon(0 0, 0% 100%, 30% 0);
      background: linear-gradient(to bottom, #54f066, #e2fa4e);
    }
  }
  &:nth-child(4) {
    background: linear-gradient(to bottom, #54f066, #e2fa4e);

    .polygon {
      clip-path: polygon(0 0, 20% 0, 40% 100%, 0% 100%);
      background: linear-gradient(to bottom left, #54f066, #e2fa4e);
    }
  }

  .type {
    position: relative;
    display: block;
    text-align: end;
    color: var(--color-7);
    font-weight: 600;
    font-size: 1.25rem;
  }

  .period {
    position: relative;
    display: block;
    text-align: end;
    color: var(--color-5);
    font-weight: 600;
    font-size: 1rem;
  }

  .counter {
    display: block;
    text-align: end;
    color: var(--color-1);
    font-weight: 600;
    font-size: 2.375rem;
  }
}

// SLIDER
