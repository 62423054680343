.checkbox {
  display: flex;
  column-gap: 10px;
}

.checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkbox .checkmark {
  position: relative;
  height: 20px;
  width: 20px;
  background-color: transparent;
  border: 1px solid #cfcfcf;
  border-radius: 3px;
  display: inline-block;
  cursor: pointer;
}

.checkbox input:checked + .checkmark {
  background-color: var(--color-2);
  border: 1px solid var(--color-2);
}

.checkbox .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.checkbox input:checked + .checkmark:after {
  display: block;
}

.checkbox .checkmark:after {
  left: 8px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid var(--color-1);
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
