/* Colors */
:root {
  --color-1: #191919;
  --color-2: #e1e506;
  --color-3: #fff;
  --color-4: #f5f5f5;
  --color-5: #525252;
  --color-6: #e9e9e9;
  --color-danger: #ec3030;
}

@font-face {
  font-family: "simplify";
  src: url("../fonts/inter/simplify.otf") format("opentype");
}
