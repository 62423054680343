input::placeholder {
  color: #d1d1d1 !important;
}

.border-color-2 {
  border-color: var(--color-2) !important;
}

.bg-color-1 {
  background-color: var(--color-1);
}

.bg-color-2 {
  background-color: var(--color-2);
}

.bg-color-3 {
  background-color: var(--color-3);
}

.bg-color-4 {
  background-color: var(--color-4);
}

.color-primary {
  color: var(--color-1);
}

.color-secondary {
  color: var(--color-2);
}

.color-white {
  color: var(--color-3) !important;
}

.flex-1 {
  flex: 1;
}

.flex-2 {
  flex: 2;
}
