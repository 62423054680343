// Transparent background and border properties included for button version.
// iOS requires the button element instead of an anchor tag.
// If you want the anchor version, it requires `href="#"`.
// See https://developer.mozilla.org/en-US/docs/Web/Events/click#Safari_Mobile

.btn-close {
  --#{$prefix}btn-close-width: #{$btn-close-width};
  --#{$prefix}btn-close-height: #{$btn-close-height};
  --#{$prefix}btn-close-padding-y: #{$btn-close-padding-y};
  --#{$prefix}btn-close-padding-x: #{$btn-close-padding-x};
  --#{$prefix}btn-close-color: #{$btn-close-color};
  --#{$prefix}btn-close-bg: #{$btn-close-bg};
  --#{$prefix}btn-close-opacity: #{$btn-close-opacity};
  --#{$prefix}btn-close-hover-opacity: #{$btn-close-hover-opacity};
  --#{$prefix}btn-close-focus-opacity: #{$btn-close-focus-opacity};
  --#{$prefix}btn-close-disabled-opacity: #{$btn-close-disabled-opacity};

  box-sizing: content-box;
  width: var(--#{$prefix}btn-close-width);
  height: var(--#{$prefix}btn-close-height);
  padding: var(--#{$prefix}btn-close-padding-y) var(--#{$prefix}btn-close-padding-x);
  color: var(--#{$prefix}btn-close-color);
  background: transparent escape-svg($btn-close-bg) center / var(--#{$prefix}btn-close-width) auto no-repeat; // include transparent for button elements
  border: 0; // for button elements
  @include border-radius();
  opacity: var(--#{$prefix}btn-close-opacity);

  // Override <a>'s hover style
  &:hover {
    color: var(--#{$prefix}btn-close-color);
    text-decoration: none;
    opacity: var(--#{$prefix}btn-close-hover-opacity);
  }

  &:focus {
    outline: 0;
    box-shadow: $btn-close-focus-shadow;
    opacity: var(--#{$prefix}btn-close-focus-opacity);
  }

  &:disabled,
  &.disabled {
    pointer-events: none;
    user-select: none;
    opacity: var(--#{$prefix}btn-close-disabled-opacity);
  }
}

.btn-close-white {
  filter: $btn-close-white-filter;
}
