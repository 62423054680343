.ddt {
  min-height: 100%; /* Riempie almeno l'intero viewport */
}

.ddt-header {
  position: relative;
  border-top: 1rem solid #daff47;

  .cover {
    background-image: url("../../images//landingPage//background-1.jpg");
    height: 5rem;
    width: 100%;
    background-size: cover;
    display: block;
    position: absolute;
    top: 0;
    left: 0;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(
        to right,
        rgba(255, 255, 255, 1),
        rgba(0, 0, 0, 0)
      );
    }

    svg {
      position: absolute;
      top: 1rem;
      left: 1rem;
      z-index: 1;
    }

    img {
      position: absolute;
      top: 1rem;
      left: 1rem;
      width: 10rem;
      top: 50%;
      transform: translateY(-50%);
      z-index: 1;
    }

    button {
      position: absolute;
      right: 1rem;
      top: 50%;
      transform: translateY(-50%);
      z-index: 1;
    }
  }
}

.ddt-footer {
  width: 100%;
  border-bottom: 1rem solid #daff47;
  text-align: center;

  img {
    height: 1.5rem;
    margin-bottom: 1rem;
  }
}

@media print {
  @page {
    margin: 1mm;
  }

  body {
    overflow: visible !important;
    background-color: white !important;
  }

  .ddt-header {
    img {
      width: 6rem;
    }
  }

  .ddt-content {
    margin: 6rem 1rem;
  }

  .ddt-header button {
    display: none;
  }

  .ddt-footer {
    position: absolute; /* Assicurati che sia assoluto durante la stampa */
    bottom: 0; /* Rimane sempre in fondo */
    // page-break-after: always; /* Forza un'interruzione di pagina dopo il footer */
  }

  .ddt-row {
    page-break-inside: avoid; /* Assicura che gli item non siano spezzati */
    break-inside: avoid; /* Ulteriore sicurezza per il layout */

    .label-2 {
      font-size: 0.625rem;
    }

    .label-5 {
      font-size: 0.75rem;
    }
  }
}

@media (min-width: 1000px) {
  .ddt-content {
    width: 80%;
    margin: 6rem auto;
  }
}
