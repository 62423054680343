.sidebar {
  position: fixed;
  top: 0;
  left: -200px;
  width: 250px;
  height: 100%;
  background-color: #111;
  color: white;
  transition: left 0.3s ease;
  overflow-x: hidden;
  z-index: 1;
  --sidebar-box-shadow: 0;
}

.sidebar-logo {
  display: flex;
  padding: 16px 20px;

  &.open {
    img {
      max-width: 130px;
      max-height: 50px;
      height: auto;
      width: auto;
    }
  }
}

.sidebar-section {
  padding-top: 20px;
}

.sidebar-open {
  left: 0;
}

.sidebar-toggle {
  position: fixed;
  top: 10px;
  left: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 1;
  transition: left 0.3s ease;
  color: white;
}

.sidebar-shape {
  content: url("../../images/sidebar-ellipse.svg");
  height: 78px;
  width: 39px;
}

.sidebar-toggle-open {
  left: 240px;
}

.sidebar-arrow {
  position: absolute;
  top: 33%;
}

.sidebar .nav-item .nav-link {
  color: white;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 0.825rem;
  align-items: center;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.sidebar .nav-item .nav-link .nav-icon-container {
  width: 25px;
}

.sidebar .nav-item .nav-link:hover,
.sidebar .nav-sidebar > .nav-item-expanded > .nav-link:not(:active),
.sidebar .nav-sidebar .nav-link:hover,
.sidebar .nav-sidebar .nav-link:focus,
.sidebar .nav-sidebar .nav-link.active {
  background-color: transparent;
}

.sidebar .nav-sidebar .nav-item-open > .nav-link:not(.disabled):not(:active) {
  background-color: transparent;
}

.sidebar .nav-item-submenu.nav-item-open > .nav-link:after {
  color: var(--color-2);
}

.sidebar {
  // Stile per i nav-link quando sidebar non ha la classe .sidebar-open
  &:not(.sidebar-open) {
    .nav-link {
      display: flex;
      flex-direction: row-reverse;

      & span {
        display: none;
      }
      &.active svg {
        color: var(--color-2);
      }
    }

    .sidebar-logo {
      flex-direction: row-reverse;
      padding-right: 10px;
    }
  }

  // Stile per i nav-link quando sidebar ha la classe .sidebar-open
  &.sidebar-open {
    .nav-link {
      // Aggiungi altri stili qui, se necessario
    }
  }
}

.nav-link.active {
  border-left-width: 3px;
  border-left-style: solid;
  border-left-color: var(--color-2);
}

.sidebar-new-badge {
  background-color: var(--color-2);
  color: var(--color-1);
  font-size: 0.5rem;
  padding: 2px;
  border-radius: 5px;
  font-weight: 700;
}
