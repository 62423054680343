.form-floating {
  --#{$prefix}form-floating-height: #{$form-floating-height};
  --#{$prefix}form-floating-line-height: #{$form-floating-line-height};
  --#{$prefix}form-floating-padding-y: #{$form-floating-padding-y};
  --#{$prefix}form-floating-padding-x: #{$form-floating-padding-x};
  --#{$prefix}form-floating-input-padding-t: #{$form-floating-input-padding-t};
  --#{$prefix}form-floating-input-padding-b: #{$form-floating-input-padding-b};
  --#{$prefix}form-floating-label-opacity: #{$form-floating-label-opacity};
  --#{$prefix}form-floating-label-transform: #{$form-floating-label-transform};

  position: relative;

  > .form-control,
  > .form-control-plaintext,
  > .form-select {
    height: var(--#{$prefix}form-floating-height);
    line-height: var(--#{$prefix}form-floating-line-height);
  }

  > label {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; // allow textareas
    padding: var(--#{$prefix}form-floating-padding-y) var(--#{$prefix}form-floating-padding-x);
    overflow: hidden;
    text-align: start;
    text-overflow: ellipsis;
    white-space: nowrap;
    pointer-events: none;
    border: $input-border-width solid transparent; // Required for aligning label's text with the input as it affects inner box model
    transform-origin: 0 0;
    @include transition($form-floating-transition);
  }

  > .form-control,
  > .form-control-plaintext {
    padding: var(--#{$prefix}form-floating-padding-y) var(--#{$prefix}form-floating-padding-x);

    &::placeholder {
      color: transparent;
    }

    &:focus,
    &:not(:placeholder-shown) {
      padding-top: var(--#{$prefix}form-floating-input-padding-t);
      padding-bottom: var(--#{$prefix}form-floating-input-padding-b);
    }
    // Duplicated because `:-webkit-autofill` invalidates other selectors when grouped
    &:-webkit-autofill {
      padding-top: var(--#{$prefix}form-floating-input-padding-t);
      padding-bottom: var(--#{$prefix}form-floating-input-padding-b);
    }
  }

  > .form-select {
    padding-top: var(--#{$prefix}form-floating-input-padding-t);
    padding-bottom: var(--#{$prefix}form-floating-input-padding-b);
  }

  > .form-control:focus,
  > .form-control:not(:placeholder-shown),
  > .form-control-plaintext,
  > .form-select {
    ~ label {
      opacity: var(--#{$prefix}form-floating-label-opacity);
      transform: var(--#{$prefix}form-floating-label-transform);
    }
  }
  // Duplicated because `:-webkit-autofill` invalidates other selectors when grouped
  > .form-control:-webkit-autofill {
    ~ label {
      opacity: var(--#{$prefix}form-floating-label-opacity);
      transform: var(--#{$prefix}form-floating-label-transform);
    }
  }

  > .form-control-plaintext {
    ~ label {
      border-width: $input-border-width 0; // Required to properly position label text - as explained above
    }
  }
}
