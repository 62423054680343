/* ------------------------------------------------------------------------------
 *
 *  # Datatables Scroller
 *
 *  Drawing the rows required for the current display only, for fast operation
 *
 * ---------------------------------------------------------------------------- */

// Check if component is enabled
@if $enable-datatable-scroller {

    // Base
    .DTS {

        // Prevent word wrap
        tbody {
            th,
            td {
                white-space: nowrap;
            }
        }

        // Loading text
        .DTS_Loading {
            position: absolute;
            top: 50%;
            left: 50%;
            width: calc(var(--#{$prefix}spacer) * 10);
            height: var(--#{$prefix}spacer);
            z-index: 1;
            border: var(--#{$prefix}border-width) solid var(--#{$prefix}border-color);
            padding: var(--#{$prefix}spacer) 0;
            text-align: center;
            transform: translate(-50%, -50%)
        }

        // Scroll body
        .dataTables_scrollBody {
            z-index: 2;
        }
    }
}
