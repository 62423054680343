.my-input,
.my-select {
  background-color: #f4f6f9;
  padding: 1rem;
  font-size: 0.875rem;
  border: none;
  border-radius: 0.5rem;
  width: 100%;
  outline: none;

  &::placeholder {
    color: #707070;
  }
}

// radio
/* Reset dello stile predefinito del pulsante di opzione */
.my-input[type="radio"] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: none;
  border: none;
  position: absolute;
  opacity: 0;
}

/* Stile dei pulsanti di opzione personalizzati */
.radio-container {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

.radio-button {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid #e1e506;
  margin-right: 8px;
  position: relative;
  transition: background-color 0.3s ease; /* Aggiunto transizione */
  cursor: pointer;
}

.radio-dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: opacity 0.3s ease; /* Aggiunto transizione */
}

/* Stile del punto bianco quando è checked */
.my-input[type="radio"]:checked + .radio-button .radio-dot {
  opacity: 1;
}

/* Aggiunto stile di sfondo al pulsante di opzione quando è checked */
.my-input[type="radio"]:checked + .radio-button {
  background-color: #e1e506;
}

.radio-container span {
  font-size: 0.75rem;
  color: var(--color-1);
}

//-----------------------------------------------

.input-box {
  width: 100%;
  padding: 7.25px 16px 7.25px 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  color: #666;
  outline: none;
  transition: border-color 0.3s ease;

  &::placeholder {
    color: #aaa;
  }

  &:focus {
    border-color: #999;
  }
}

.input-box-icon {
  position: relative;
  display: inline-block;

  .icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    color: #666;
  }

  &.left {
    .icon {
      left: 12px;
    }

    .input-box,
    .form-control {
      padding-left: 40px;
    }
  }

  &.right {
    .icon {
      right: 12px;
    }
    .input-box,
    .form-control {
      padding-right: 40px;
    }
  }

  &.invalid {
    .icon {
      top: 32%;
    }
  }
}
