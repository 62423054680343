// body input::placeholder {
//   color: rgb(196, 196, 196) !important;
// }

//React select
.css-b62m3t-container.is-invalid .css-13cymwt-control {
  border-color: #ef4444;
}

// //Loader
// .loading-indicator:before {
//   content: "";
//   background: #6b6b6bb2;
//   position: fixed;
//   width: 100%;
//   height: 100%;
//   top: 0;
//   left: 0;
//   z-index: 99998;
// }

// .loading-indicator:after {
//   content: "Caricamento...";
//   position: fixed;
//   width: 100%;
//   top: 50%;
//   left: 0;
//   z-index: 99999;
//   color: white;
//   text-align: center;
//   font-weight: bold;
//   font-size: 1.5rem;
// }

// //Tracking event
// .tracking-event-container {
//   text-align: center;
//   color: white;
//   padding: 1rem;
// }

// .tracking-event-status-name {
//   text-align: center;
//   color: white;
//   font-size: 1.25rem;
//   font-weight: 700;
// }

// .tracking-event-location {
//   text-align: center;
//   color: white;
//   font-size: 0.75rem;
//   font-weight: 500;
// }

// .tracking-event-note {
//   text-align: center;
//   color: white;
//   font-size: 0.625rem;
//   font-weight: 500;
// }

// hr.dotted {
//   border: none;
//   height: 1px;
//   background-image: linear-gradient(to right, #000 40%, transparent 10%);
//   background-position: 0 0;
//   background-size: 5px 1px;
//   background-repeat: repeat-x;
// }
