.link {
  display: block;
  font-size: 0.875rem;
  text-decoration: none;
  color: var(--color-5);

  svg {
    margin: 0 0.5rem;
  }

  &:hover {
    color: var(--color-5);
    text-decoration: underline;
  }
}
