//Tracking code

.tracking-code-title {
  font-size: 12px;
  color: #677684;
  text-transform: uppercase;
  display: block;
}

.tracking-code {
  border-radius: 30px;
  background-color: var(--color-2);
  padding: 0rem 1rem;
  color: var(--color-1);
  font-size: 1rem;
  display: inline-block;
}

//Status badge
.status-container {
  width: 95%;
  border-radius: 0.25rem;
  display: inline-block;
  padding: 0.5rem 1.25rem;
  text-align: center;
  color: white;
}

.status-container label {
  font-size: 12px;
  display: block;
  text-transform: uppercase;
}

.status-name {
  color: white;
  font-weight: 800;
  font-size: 1.5rem;
}

.status-name-small {
  color: white;
  font-weight: 800;
  font-size: 1rem;
}
.status-event-date {
  display: block;
}

.show-all-events {
  color: #677684;
  font-size: 13px;
  text-decoration: underline;
}

.all-events-container {
  // margin-top: 2rem;
}

.events-of-day-header {
  background-color: #f4f6f9;
  padding: 5px 15px;
  width: 100%;
  color: var(--color-1);
  font-weight: 600;
  font-size: 13px;
  // margin-bottom: 2rem;
}

.event-status-container {
  display: flex;
  align-items: center;
}

.event-status-stamp {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  display: block;
  margin-right: 0.5rem;
}

.event-status-name {
  color: #677684;
  font-size: 12px;
  font-weight: 700;
  display: block;
  text-transform: uppercase;
  letter-spacing: 1px;
  flex: 1;
}

.event-time {
  font-size: 12px;
  font-weight: 600;
  color: #677684;
  display: block;
}

.event-note {
  font-size: 14px;
  font-weight: 700;
  color: var(--color-1);
  display: block;
}

.event-location {
  font-size: 13px;
  color: #677684;
  text-transform: uppercase;
}

.my-card {
  border-radius: 0.5rem;
  padding: 1.5rem;

  &.border-color-2 {
    border-top: 4px solid var(--color-2);
    border-bottom: 4px solid var(--color-2);
  }
}

.my-card-label-title {
  font-size: 1rem;
  text-transform: uppercase;
  font-weight: 800;
  color: var(--color-1);
  display: block;
}

.my-card h1 {
  font-size: 2rem;
  font-weight: 800;
}

.timeline-horizontal-container {
  display: flex;
  align-items: start;
  justify-content: center;
  height: 7rem;

  & .event-container {
    position: absolute;
    top: calc(100% + 20px);
    left: 50%;
    transform: translate(-50%, 0);
    display: inline-block;
    text-align: center;
    width: 7rem;
  }

  & .event-name {
    display: block;
    color: #677684;
    font-size: 13px;
    font-weight: 600;
  }

  & .event-date {
    font-size: 11px;
  }
}

.timeline-horizontal-container ol,
.timeline-horizontal-container li {
  margin: 0;
  padding: 0;
}

.timeline-horizontal-container ol {
  display: flex;
  width: 80%;
  justify-content: space-between;
  position: relative;

  &:after {
    content: "";
    display: block;
    width: 100%;
    height: 3px;
    background-color: var(--color-4);
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(0, -50%);
    z-index: 0;
  }

  &:before {
    content: "";
    display: block;
    width: 100%;
    height: 3px;
    background-color: var(--color-2);
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(0, -50%);
    z-index: 0;
  }
}

.timeline-horizontal-container ol:has(.active:nth-child(1)):after {
  width: calc(75% + 60px);
}
.timeline-horizontal-container ol:has(.active:nth-child(1)):before {
  width: calc(25% - 60px);
}

.timeline-horizontal-container ol:has(.active:nth-child(2)):after {
  width: 50%;
}

.timeline-horizontal-container ol:has(.active:nth-child(2)):before {
  width: 50%;
}

.timeline-horizontal-container ol:has(.active:nth-child(3)):after {
  width: calc(25% - 60px);
}
.timeline-horizontal-container ol:has(.active:nth-child(3)):before {
  width: calc(75% + 60px);
}

.timeline-horizontal-container ol:has(.active:nth-child(4)):after {
  width: 0%;
}
.timeline-horizontal-container ol:has(.active:nth-child(4)):before {
  width: 100%;
}

.timeline-horizontal-container li {
  list-style-type: none;
  position: relative;
  z-index: 1;

  &:not(.EXCEPTION)::after {
    content: "";
    display: block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: var(--color-1);
    border: 4px solid var(--color-4);
  }
}

.timeline-horizontal-container li.EXCEPTION {
  width: 30px;
  height: 30px;
  background-color: #fd5050;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: calc(75% + 30px);
  top: -20%;
  color: #191919;

  & i {
    color: var(--color-3);
    font-size: 18px;
    font-weight: bold;
  }
}

.timeline-horizontal-container li.RET_SENDER {
  width: 30px;
  height: 30px;
  background-color: #585a60;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: calc(75% + 30px);
  top: -20%;
  color: #191919;

  & svg {
    color: var(--color-3);
    font-size: 18px;
    font-weight: bold;
  }
}

.timeline-horizontal-container li.active:after {
  border-color: var(--color-2);
}

.timeline-horizontal-container li:not(.active) .event-name {
  color: rgba(103, 118, 132, 0.6);
}

@media (max-width: 768px) {
  .my-card h1 {
    font-size: 1.5rem;
  }
}

@media (max-width: 575px) {
  .status-name {
    font-size: 12px;
  }

  .mb-xs {
    margin-bottom: 7rem !important;
  }
}

@media (min-width: 576px) and (max-width: 768px) {
  .status-name {
    font-size: 13px;
  }
}

@media (max-width: 768px) {
  .status-container {
    padding: 0.25rem 1rem;
  }
}

@media (max-width: 992px) {
  .status-container {
    width: 100%;
  }
}
