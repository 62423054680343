.table-hover > tbody > tr.table-success > * {
  --table-accent-bg: var(--table-hover-bg);
  color: var(--table-hover-color);
}

div.form-check-input{
  border:none!important;
}

@keyframes scaleFromZeroToOne {
  from {
    transform: scaleX(0);
  }

  to {
    transform: scaleX(1);
  }
}

@keyframes scaleFromOneToZero {
from{
  transform: scaleX(1);
}
  to {
    transform: scaleX(0);
  }
}

.multi-action-label{
  position: absolute;
  padding-top: 5px;
}

.scale-animation{
  animation-duration: 0.3s;
  animation-iteration-count: 1;
  animation-timing-function: ease-in-out;
  animation-fill-mode: both;
}

.scale-animation-delay{
  animation-delay: 0.3s;
}

.scale-from-one-to-zero{
  animation-name: scaleFromOneToZero;
}

.scale-from-zero-to-one{
  animation-name: scaleFromZeroToOne;
}

.pinning {
  position: -webkit-sticky;
  position: sticky;
  background-color: white !important;
}

.pinning-left {
  left: 0;
}

.pinning-right {
  right: 0;
}
