.label-input {
  color: var(--color-1);
  font-size: 0.75rem;
  margin-top: 1rem;
  display: block;
}

.label-1 {
  font-weight: bold;
  font-size: 0.75rem;
  color: var(--color-1);
  display: block;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.label-2 {
  font-weight: 500;
  font-size: 0.75rem;
  color: var(--color-5);
  display: block;
  word-wrap: break-word;
  overflow-wrap: break-word;

  &.white {
    color: var(--color-3);
  }
}

.label-3 {
  font-weight: 500;
  font-size: 0.875rem;
  color: var(--color-1);
  display: block;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.label-4 {
  font-weight: bold;
  font-size: 1.125rem;
  color: var(--color-1);
  display: block;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.label-5 {
  font-weight: lighter;
  font-size: 0.875rem;
  color: var(--color-1);
  display: block;
  word-wrap: break-word;
  overflow-wrap: break-word;
}
