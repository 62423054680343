body {
  background-color: var(--color-4);
}

h2 {
  display: flex;
  align-items: center;
  color: var(--color-1);
  font-weight: 300;
  column-gap: 1rem;
  font-size: 1.5rem;
}

h2::before {
  content: "";
  display: block;
  width: 1rem;
  height: 1rem;
  background-color: var(--color-2);
}

h3 {
  font-size: 1rem;
  font-weight: bold;
  color: var(--color-1);
}

svg:focus {
  outline: none;
}

.app {
  display: flex;
  flex-direction: column;
}

.page-name {
  position: fixed;
  left: 100px;
  top: 16px;
  color: var(--color-1);
  transition: left 0.3s ease;

  & label {
    letter-spacing: 1px;
    font-weight: 600;
  }
}

.page-name.open {
  left: 300px;
}

.box-container {
  width: 100%;
  background-color: var(--color-3);
  border-radius: 6px;
  padding: 1rem;
}

.grid-column {
  grid-auto-flow: column;
  gap: 0.5rem;
}

.navbar {
  @media (max-width: 992px) {
    background-color: var(--color-1);
    z-index: 11;
  }
}

.nav-sidebar .nav-link:hover,
.nav-sidebar .nav-link:focus {
  background-color: var(--color-2);
}

.content {
  padding: 2rem;
}

.content-unshift {
  margin-left: 50px;
  transition: margin-left 0.3s ease;

  @media (max-width: 992px) {
    margin-left: 0;
  }
}

.content-shift {
  margin-left: 250px;

  @media (max-width: 992px) {
    margin-left: 0;
  }
}

.content-banner {
  margin-top: 150px;
}

.banner-1 {
  background-image: url("../../assets/images/landingPage/background-1.jpg");
  height: 100px;
  width: 100%;
  background-size: cover;
  display: block;
  position: absolute;
  top: 0;
  left: 0;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.9),
      rgba(0, 0, 0, 0.6)
    );
  }
}

.footer {
  position: fixed;
  width: 100%;
  background-color: #eff0f6;
  bottom: 0;
}

.footer-content {
  display: flex;
  justify-content: space-evenly;
  padding: 5px;
}

//Hamburger

.hamburger {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 24px;
  position: relative;
  z-index: 2;
  margin: 1rem;
}

.line {
  width: 30px;
  height: 3px;
  background-color: white;
  transition: all 0.3s ease;
}

.nav-links {
  list-style: none;
  margin: 0;
  padding: 0;
  height: 100%;
  z-index: 1;
  position: absolute;
  left: -100%;
  transition: all 0.5s ease;
}

.nav-links.open {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--color-1);
  position: absolute;
  left: 0;
  width: 100%;
  transition: all 0.5s ease;
  overflow-y: scroll;
  z-index: 10;
}

.nav-links li {
  margin: 10px 0;
}

.nav-links a {
  color: white;
  text-decoration: none;
}

.hamburger .line.open:nth-child(1) {
  transform: rotate(45deg) translateY(11px);
}

.hamburger .line.open:nth-child(2) {
  opacity: 0;
}

.hamburger .line.open:nth-child(3) {
  transform: rotate(-45deg) translateY(-11px);
}

@media (min-width: 992px) {
  .hamburger {
    display: none;
  }

  .nav-links {
    display: flex;
    flex-direction: row;
  }

  .nav-links.open {
    position: static;
    flex-direction: row;
    background: none;
  }

  .nav-links li {
    margin: 0 10px;
  }
}
