.weekly-calendar-container {
  display: flex;
  column-gap: 2rem;

  .day {
    .name {
      display: block;
      color: var(--color-5);
      text-align: center;
      margin-bottom: 1rem;
    }

    .number {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 100%;
      width: 3rem;
      height: 3rem;
      // background-color: var(--color-1);
      color: var(--color-1);
      border: 2px solid var(--color-6);
      font-weight: 600;
      cursor: pointer;

      &.active {
        border: none;
        color: var(--color-3);
        background-color: var(--color-1);
        cursor: default;
      }
    }

    .signal,
    .counter {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 2rem;
    }

    .signal span {
      width: 0.5rem;
      height: 0.5rem;
      background-color: #2dc9eb;
      border-radius: 100%;
    }

    .counter span {
      color: var(--color-5);
      font-weight: bold;
      font-size: 10px;
    }
  }
}

.weekly-calendar-details {
  display: flex;
  justify-content: space-between;
  width: 100%;
  column-gap: 10px;
  align-items: center;

  .details-container {
    width: 100%;

    .detail {
      display: flex;
      width: 100%;
      background-color: var(--color-6);
      justify-content: space-between;
      padding: 0.5rem;
      margin: 0.5rem 0;
      border-radius: 10px;

      .day {
        display: flex;
        background-color: var(--color-3);
        border-radius: 10px;
        text-align: center;
        flex-direction: column;
        align-items: center;
        padding: 0.5rem 0.75rem;
        -webkit-box-shadow: 3px 5px 6px -5px #000000;
        box-shadow: 3px 5px 6px -5px #000000;
        margin-right: 1rem;
        line-height: 1;

        .name {
          display: block;
        }
        .number {
          font-size: 1.5rem;
          font-weight: 600;
        }
      }

      .content {
        width: 100%;
        padding: 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }

      .logo {
        background-color: var(--color-3);
        border-radius: 10px;
        padding: 0.75rem;
      }
    }
  }
}
