/* ------------------------------------------------------------------------------
*
*  # D3.js library
*
*  Basic styles for D3.js visualization library
*
* ---------------------------------------------------------------------------- */

// Check if component is enabled
@if $enable-d3 {
  // Base
  // ------------------------------

  // Text color
  .d3-text {
    fill: $chart-text-color;
  }

  // Border color for slices
  .d3-slice-border {
    stroke: var(--#{$prefix}card-bg);
    stroke-width: 1.5px;
  }

  // Empty state background
  .d3-state-empty {
    fill: $chart-empty-bg;
  }

  // Background color
  .d3-bg {
    fill: var(--#{$prefix}card-bg);
  }

  // Axis
  // ------------------------------

  // Base
  .d3-axis {
    // Lines
    path,
    line {
      stroke: $chart-axis-line-color;
      shape-rendering: crispEdges;
    }

    // Axis line
    path {
      fill: none;
    }

    // Ticks
    .tick {
      &,
      text {
        font-size: $chart-text-size;
        fill: $chart-axis-text-color;
      }
    }
  }

  // Transparent axis
  .d3-axis-transparent {
    path {
      stroke: none;
    }
  }

  // Axis text titles
  .d3-axis-title {
    font-size: $chart-text-size;
    fill: $chart-text-color;
  }

  // Tooltip
  // ------------------------------

  //
  // Basic tooltip
  //

  // Base
  .d3-tip {
    --#{$prefix}d3-tooltip-padding-x: #{$tooltip-padding-x};
    --#{$prefix}d3-tooltip-padding-y: #{$tooltip-padding-y};
    @include rfs($tooltip-font-size, --#{$prefix}d3-tooltip-font-size);
    --#{$prefix}d3-tooltip-color: #{$tooltip-color};
    --#{$prefix}d3-tooltip-bg: #{$tooltip-bg};
    --#{$prefix}d3-tooltip-arrow-width: #{$tooltip-arrow-width};
    --#{$prefix}d3-tooltip-arrow-height: #{$tooltip-arrow-height};
    --#{$prefix}d3-tooltip-border-radius: #{$tooltip-border-radius};

    @include color-scheme(dark) {
      --#{$prefix}d3-tooltip-color: #{$tooltip-darkmode-color};
      --#{$prefix}d3-tooltip-bg: #{$tooltip-darkmode-bg};
    }

    position: absolute;
    padding: var(--#{$prefix}d3-tooltip-padding-y)
      var(--#{$prefix}d3-tooltip-padding-x);
    background-color: var(--#{$prefix}d3-tooltip-bg);
    color: var(--#{$prefix}d3-tooltip-color);
    margin-bottom: calc(var(--#{$prefix}d3-tooltip-arrow-height) * -1);
    font-size: var(--#{$prefix}d3-tooltip-font-size);
    z-index: $zindex-tooltip;
    @include box-shadow(var(--#{$prefix}box-shadow));
    @include border-radius(var(--#{$prefix}d3-tooltip-border-radius));

    // Arrow
    .d3-tip-arrow {
      position: absolute;
      width: 0;
      height: 0;
      border-color: transparent;
      border-style: solid;
    }
  }

  // Tooltip positioning
  .d3-tip {
    &.n .d3-tip-arrow {
      bottom: calc(var(--#{$prefix}d3-tooltip-arrow-height) * -1);
      left: 50%;
      border-width: var(--#{$prefix}d3-tooltip-arrow-height)
        var(--#{$prefix}d3-tooltip-arrow-height)
        0;
      border-top-color: var(--#{$prefix}d3-tooltip-bg);
      transform: translateX(-50%);
    }
    &.e .d3-tip-arrow {
      top: 50%;
      transform: translateY(-50%);
      /*rtl:begin:ignore*/
      left: calc(var(--#{$prefix}d3-tooltip-arrow-height) * -1);
      border-width: var(--#{$prefix}d3-tooltip-arrow-height)
        var(--#{$prefix}d3-tooltip-arrow-height)
        var(--#{$prefix}d3-tooltip-arrow-height)
        0;
      border-right-color: var(--#{$prefix}d3-tooltip-bg);
      /*rtl:end:ignore*/
    }
    &.w .d3-tip-arrow {
      top: 50%;
      transform: translateY(-50%);
      /*rtl:begin:ignore*/
      right: calc(var(--#{$prefix}d3-tooltip-arrow-height) * -1);
      border-width: var(--#{$prefix}d3-tooltip-arrow-height)
        0
        var(--#{$prefix}d3-tooltip-arrow-height)
        var(--#{$prefix}d3-tooltip-arrow-height);
      border-left-color: var(--#{$prefix}d3-tooltip-bg);
      /*rtl:end:ignore*/
    }
    &.s .d3-tip-arrow {
      top: 0;
      left: 50%;
      border-width: 0
        var(--#{$prefix}d3-tooltip-arrow-height)
        var(--#{$prefix}d3-tooltip-arrow-height);
      border-bottom-color: var(--#{$prefix}d3-tooltip-bg);
      transform: translateX(-50%);
    }
  }

  // Chart types
  // ------------------------------

  // Lines
  .d3-line {
    fill: none;

    // Thin stroke
    &-thin {
      stroke-width: 1px;
    }

    // Medium stroke
    &-medium {
      stroke-width: 1.5px;
    }

    // Strong stroke
    &-strong {
      stroke-width: 2px;
    }
  }

  // Line circles
  .d3-line-circle {
    fill: var(--#{$prefix}card-bg);
    cursor: pointer;

    // Thin stroke
    &-thin {
      stroke-width: 1px;
    }

    // Medium stroke
    &-medium {
      stroke-width: 1.5px;
    }

    // Strong stroke
    &-strong {
      stroke-width: 2px;
    }
  }

  // Connect lines
  .d3-line-connect {
    fill: none;
    stroke: $chart-line-color;
  }

  // Grid
  // ------------------------------

  // Basic grid
  .d3-grid {
    .tick {
      line {
        stroke-width: 1px;
        stroke: $chart-line-color;
      }
    }
  }

  // Dashed grid
  .d3-grid-dashed {
    .tick {
      line {
        stroke-dasharray: 4, 2;
        stroke: $chart-line-color;
      }
    }

    path {
      stroke-width: 0;
    }
  }

  // Components
  // ------------------------------

  // Crosshair overlay
  .d3-crosshair-overlay {
    fill: none;
    pointer-events: all;
  }

  // Crosshair pointer
  .d3-crosshair-pointer {
    text {
      fill: var(--#{$prefix}body-color);
    }
  }

  // Crosshair line
  .d3-crosshair-line {
    fill: none;
    stroke: $chart-line-color;
    stroke-width: 1px;
    shape-rendering: crispEdges;
    pointer-events: none;
  }

  // Bullets
  // ------------------------------

  //
  // Base
  //

  // Ticks
  .bullet-tick {
    // Line styles
    line {
      stroke: $chart-axis-line-color;
      stroke-width: 1px;
      shape-rendering: crispEdges;
    }

    // Text styles
    text {
      fill: $chart-axis-text-color;
      font-size: $chart-text-size;
    }
  }

  // Marker
  .bullet-marker {
    stroke-width: 2px;
    shape-rendering: crispEdges;
  }

  // Title
  .bullet-title {
    fill: $chart-text-color;
    font-weight: $font-weight-semibold;
  }

  // Subtitle
  .bullet-subtitle {
    fill: $chart-text-color;
  }

  //
  // First bullet
  //

  // Ranges
  .bullet-1 .bullet-range-1 {
    fill: rgba(var(--#{$prefix}primary-rgb), 0.8);
  }
  .bullet-1 .bullet-range-2 {
    fill: rgba(var(--#{$prefix}primary-rgb), 0.4);
  }
  .bullet-1 .bullet-range-3 {
    fill: var(--#{$prefix}primary);
  }

  // Measures
  .bullet-1 .bullet-measure-1 {
    fill: rgba(var(--#{$prefix}primary), 0.9);
  }
  .bullet-1 .bullet-measure-2 {
    fill: var(--#{$prefix}white);
  }

  // Marker
  .bullet-1 .bullet-marker {
    stroke: rgba(var(--#{$prefix}black), 0.25);
  }

  //
  // Second bullet
  //

  // Ranges
  .bullet-2 .bullet-range-1 {
    fill: rgba(var(--#{$prefix}danger-rgb), 0.6);
  }
  .bullet-2 .bullet-range-2 {
    fill: rgba(var(--#{$prefix}danger-rgb), 0.3);
  }
  .bullet-2 .bullet-range-3 {
    fill: var(--#{$prefix}danger);
  }

  // Measures
  .bullet-2 .bullet-measure-1 {
    fill: rgba(var(--#{$prefix}danger-rgb), 0.9);
  }
  .bullet-2 .bullet-measure-2 {
    fill: var(--#{$prefix}white);
  }

  // Marker
  .bullet-2 .bullet-marker {
    stroke: rgba(var(--#{$prefix}black), 0.25);
  }

  //
  // Third bullet
  //

  // Ranges
  .bullet-3 .bullet-range-1 {
    fill: rgba(var(--#{$prefix}success-rgb), 0.7);
  }
  .bullet-3 .bullet-range-2 {
    fill: rgba(var(--#{$prefix}success-rgb), 0.35);
  }
  .bullet-3 .bullet-range-3 {
    fill: var(--#{$prefix}success);
  }

  // Measures
  .bullet-3 .bullet-measure-1 {
    fill: rgba(var(--#{$prefix}success-rgb), 0.9);
  }
  .bullet-3 .bullet-measure-2 {
    fill: var(--#{$prefix}white);
  }

  // Marker
  .bullet-3 .bullet-marker {
    stroke: rgba(var(--#{$prefix}black), 0.25);
  }

  // Progress counter icon. Styles moved to
  // CSS, because IE9 drove me crazy...
  // ------------------------------

  .counter-icon {
    font-size: calc(var(--#{$prefix}icon-font-size) * 2);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  // Legend
  // ------------------------------

  .d3-legend {
    text {
      fill: $chart-text-color;
      font-size: $chart-text-size;
    }

    line {
      stroke: $chart-axis-line-color;
      stroke-width: 1px;
    }
  }

  .chart-widget-legend {
    margin: var(--#{$prefix}spacer-2) 0 0 0;
    padding: 0;
    fill: $chart-text-color;
    font-size: $chart-text-size;
    text-align: center;

    li {
      margin: var(--#{$prefix}spacer-1) var(--#{$prefix}spacer-2) 0;
      padding: var(--#{$prefix}spacer-2)
        var(--#{$prefix}spacer-2)
        var(--#{$prefix}spacer-1);
      display: inline-block;
    }
  }
}
