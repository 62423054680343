.header {
  position: relative;
  height: 65px;
  display: flex;
  padding: 2.5px;
}

.header .after {
  position: absolute;
  top: 0;
  right: 0;
  height: 65px;
  z-index: 2;
  width: 20%;
}

.header .logo {
  margin-left: 2rem;
}
