.manifest-header {
  position: relative;
  height: 20vh;
  .polygon {
    clip-path: polygon(100% 100%, 0 0, 100% 0);
    background-color: var(--color-2);
    height: 10vh;
    width: 100%;
  }
  img {
    position: absolute;
    top: 1rem;
    left: 1rem;
    width: 10rem;
    top: 50%;
    transform: translateY(-50%);
  }
  button {
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
  }
}

.manifest-content {
  img {
    width: 7.5rem;
  }
}

.manifest-footer {
  position: relative;
  height: 20vh;
  bottom: 0; /* Posiziona il footer al fondo del contenitore */
  left: 0;
  right: 0;
  .polygon {
    clip-path: polygon(0 0, 0% 100%, 100% 100%);
    background-color: var(--color-2);
    height: 10vh;
    width: 100%;
  }
}

/* Stili di base per la griglia */
.row-1 {
  display: grid;
  grid-template-columns: 0.1fr 1fr 0.75fr 1.5fr 0.5fr 0.75fr 0.25fr 1fr;
  gap: 0.5rem; /* Spaziatura tra le colonne */
}

.row-2 {
  display: grid;
  grid-template-columns: 2fr 4fr 1fr 0.5fr; /* Definisce larghezze diverse per ogni colonna */
  gap: 0.5rem; /* Spaziatura tra le colonne */
}

@media print {
  @page {
    margin: 1mm;
  }

  body {
    overflow: visible !important;
  }

  .manifest-header {
    height: 8vh;

    .polygon {
      height: 4vh;
    }

    img {
      width: 6rem;
    }
  }

  .manifest-header button {
    display: none;
  }

  .manifest-footer {
    position: absolute; /* Assicurati che sia assoluto durante la stampa */
    bottom: 0; /* Rimane sempre in fondo */
    page-break-after: always; /* Forza un'interruzione di pagina dopo il footer */

    .polygon {
      bottom: 0;
    }
  }

  /* Evita che i ListItem vengano tagliati a metà tra le pagine */
  .list-item {
    page-break-inside: avoid; /* Assicura che gli item non siano spezzati */
    break-inside: avoid; /* Ulteriore sicurezza per il layout */

    .label-2 {
      font-size: 0.625rem;
    }

    .label-5 {
      font-size: 0.75rem;
    }
  }
}

@media (min-width: 1000px) {
  .manifest-content {
    width: 80%;
    margin: 0 auto;
  }
}
