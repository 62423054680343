// Generate table variants for dark theme
@mixin table-dark-variant($state, $background) {
	.table-#{$state} {
		@include color-scheme(dark) {
			$color: color-contrast(opaque($body-darkmode-bg, $background));
			$hover-bg: mix($color, $background, percentage($table-hover-bg-factor));
			$striped-bg: mix($color, $background, percentage($table-striped-bg-factor));
			$active-bg: mix($color, $background, percentage($table-active-bg-factor));
			$border-color: mix($color, $background, percentage($table-border-factor));

			--#{$prefix}table-color: #{$color};
			--#{$prefix}table-bg: #{$background};
			--#{$prefix}table-border-color: #{$border-color};
			--#{$prefix}table-striped-bg: #{$striped-bg};
			--#{$prefix}table-striped-color: #{color};
			--#{$prefix}table-active-bg: #{$active-bg};
			--#{$prefix}table-active-color: #{color};
			--#{$prefix}table-hover-bg: #{$hover-bg};
			--#{$prefix}table-hover-color: #{color};

			color: var(--#{$prefix}body-color);
			border-color: var(--#{$prefix}table-border-color);
		}
	}
}
